export enum CompanyType {
  None,
  NaturalPerson,
  Bvba,
  Nv,
  Vof,
  CommV,
  Vzw,
  Ms,
  Cvba,
  Cvoa,
  Fv,
  Gcv,
  Son,
  OtherLegalForm,
  PrivateFoundation,
  Vvzrl,
  BtwE,
  NvSa,
  Unknown
}
