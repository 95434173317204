import { Pipe, PipeTransform } from '@angular/core';
import { CompanyType } from 'src/app/model';

@Pipe({
  name: 'companyLabel'
})
export class CompanyLabelPipe implements PipeTransform {

  transform(value: CompanyType): string {
    // Get the actual enum name
    const nameOfCompanyTypeEnum = CompanyType[value];

    if (value === CompanyType.None || value === CompanyType.Unknown) {
      return ''; // No need to specify 'n.v.t' or anything else if it's not a company
    }
    if (!nameOfCompanyTypeEnum) {
      return '';
    }
    return `COMPANYTYPE.${nameOfCompanyTypeEnum.toUpperCase()}`;
  }
}
